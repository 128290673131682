import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/seo"

import { Section, Container } from "../components/Containers"
import { Text, Image, GeneralHours } from "../components/Core"
import { FullImageText } from "../components/ImageText"
import { telephoneStrip } from "../utils"

const ContactPage = ({ pageContext, data }) => {
  const { metaTitle, metaDescription, socialImage, schemaPage } = pageContext
  const {
    hero,
    heading,
    address,
    parking,
    access,
    map,
    directions,
    transportation,
    affiliations
  } = data.allUniquePagesJson.nodes[0]

  const adrs = data.address.nodes[0]
  const location = {
    street: `${adrs.streetNumber} ${adrs.streetName}`,
    cityZip: `${adrs.cityName}, ${adrs.stateAbbr} ${adrs.cityZip}`
  }

  return (
    <Layout className="contact-page">
      <SEO
        title={metaTitle}
        description={metaDescription}
        socialImage={socialImage}
        schemaPage={schemaPage}
      />

      <Image wrapperClassName="gradient-curtain" publicId={hero.image} useAR />

      <Section>
        <Container small>
          <Text className="has-text-centered" as="h1" text={heading} />
        </Container>
      </Section>

      <FullImageText className="contact__nap" reverse image={address.image}>
        <div className="even-grid--2 mobile-collapse">
          <div>
            <h5 className="mb-1">Address</h5>
            <a
              href={adrs.googlePlaceLink}
              title="Get directions"
              target="_blank">
              <Text className="color-blue" as="p" text={location.street} />
              <Text
                className="mt-0 color-blue"
                as="p"
                text={location.cityZip}
              />
            </a>

            <h5 className="mt-3 mb-1">Phone</h5>
            <a
              href={`tel:+1${telephoneStrip(adrs.phoneNumber)}`}
              title="Give us a call">
              {adrs.phoneNumber}
            </a>

            <h5 className="mt-3 mb-1">Email</h5>
            <a href={`mailto:${adrs.email}`} title="Send us an email">
              {adrs.email}
            </a>
          </div>

          <div className="mt-3--mobile">
            <h5>Office Business Hours</h5>
            <GeneralHours locationHours={adrs.officeHours} />

            <h5 className="mt-3">Dockside Tours</h5>
            <Link
              title="Navigate to the calendar"
              to="/events/#fh-calendar"
              className="mt-3">
              View Schedule →
            </Link>
          </div>
        </div>
      </FullImageText>

      <Section>
        <Container small>
          <div className="even-grid--2 mobile-collapse">
            <Text text={parking} />
            <Text className="mt-1--mobile" text={access} />
          </div>
        </Container>
      </Section>

      <FullImageText image={map.image}>
        <div>
          <Text className="mb-3" text={map.text} />
        </div>
      </FullImageText>

      <Section>
        <Container small>
          {/* <div className="even-grid--2 mobile-collapse"> */}
          <Text text={directions.left} />
          <Text className="mt-2" text={directions.right} />
          {/* </div> */}
        </Container>
      </Section>

      <Section>
        <Container small>
          <Text className="mb-4" text={transportation.heading} />
          {/* <div className="even-grid--2 mobile-collapse"> */}
          <Text text={transportation.left} />
          <Text className="mt-2" text={transportation.right} />
          {/* </div> */}
        </Container>
      </Section>

      <Section className="contact-page__affiliations">
        <Container small>
          <Text className="mb-4" text={affiliations.heading} />
          <div className="even-grid--2 mobile-collapse">
            <Text text={affiliations.left} />
            <Text className="mt-1--mobile" text={affiliations.right} />
          </div>
        </Container>
      </Section>
    </Layout>
  )
}

export const contactQuery = graphql`
  query contactquery($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        hero {
          image
        }
        heading
        address {
          image
        }
        parking
        access
        map {
          text
          image
        }
        directions {
          left
          right
        }
        transportation {
          heading
          left
          right
        }
        affiliations {
          heading
          left
          right
        }
      }
    }
    address: allLocationsJson {
      nodes {
        officeHours {
          endTime
          startTime
          weekDay
        }
        cityName
        cityZip
        googlePlaceLink
        phoneNumber
        email
        stateAbbr
        streetName
        streetNumber
      }
    }
  }
`

export default ContactPage
